import { defineMessages } from 'react-intl';

const definedMessages = defineMessages({
    dont_show_again: {
        id: 'dont.show.again',
        defaultMessage: "Don't show again",
        description: '',
    },
    login_confirm_save: {
        id: 'login.confirm.save',
        defaultMessage: 'Save',
        description: 'Save',
    },
    login_apple_continue: {
        id: 'login.apple.continue',
        defaultMessage: 'To continue to the Obalero App please enter your full name',
        description: 'Apple login message',
    },
    login_google_continue: {
        id: 'login.google.continue',
        defaultMessage: 'To continue to the Obalero App please enter your full name',
        description: 'Google login message',
    },

    packshots_header: {
        id: 'packshotz.header',
        defaultMessage: 'Packshots',
        description: 'Packshots headline',
    },
    products_header: {
        id: 'products.header',
        defaultMessage: 'Products',
        description: 'Products headline',
    },
    packshots_welcome_header: {
        id: 'packshotz.welcome.header',
        defaultMessage: 'There are no packshotz... yet',
        description: 'Packshots welcome headline',
    },
    watch_tutorial: {
        id: 'watch.tutorial',
        defaultMessage: 'Watch tutorial',
        description: 'Watch tutorial',
    },
    footer_contact_us: {
        id: 'footer.contact_us',
        defaultMessage: 'Contact us',
        description: 'Contact us',
    },

    footer_privacy_terms: {
        id: 'footer.privacy_terms',
        defaultMessage: 'Privacy & Terms',
        description: 'Privacy & Terms',
    },

    products_welcome_header: {
        id: 'products.welcome.header',
        defaultMessage: 'Start your perfect design by choosing your package type',
        description: 'Products welcome headline',
    },
    products_type_combiblocs: {
        id: 'products.type.combiblocs',
        defaultMessage: 'Cartons',
        description: 'Products type - Cartons',
    },
    products_type_shrink_foils: {
        id: 'products.type.shrink_foils',
        defaultMessage: 'Shrink Foils',
        description: 'Products type - Shrink Foils',
    },
    products_type_glassbottles: {
        id: 'products.type.glassbottles',
        defaultMessage: 'Glass Bottles',
        description: 'Products type - Glass Bottles',
    },
    products_type_cups: {
        id: 'products.type.cups',
        defaultMessage: 'Cups',
        description: 'Products type - Cups',
    },
    products_type_cans: {
        id: 'products.type.cans',
        defaultMessage: 'Cans',
        description: 'Products type - Cans',
    },
    products_descriptions_version: {
        id: 'products.descriptions.version',
        defaultMessage: 'Version',
        description: 'Product descriprtion version',
    },
    products_descriptions_version_plural: {
        id: 'products.descriptions.version_plural',
        defaultMessage: 'Versions',
        description: 'Product description version',
    },



    products_descriptions_packshot: {
        id: 'products.descriptions.packshot',
        defaultMessage: 'Packshot',
        description: 'Product descriprtion packshot',
    },
    products_descriptions_packshot_semi_plural: {
        id: 'products.descriptions.packshot_semi_plural',
        defaultMessage: 'Packshoty',
        description: 'Product descriprtion packshoty',
    },
    products_descriptions_packshot_plural: {
        id: 'products.descriptions.packshot_plural',
        defaultMessage: 'Packshots',
        description: 'Product description packshot',
    },



    products_descriptions_versions: {
        id: 'products.descriptions.versions',
        defaultMessage: 'Versions',
        description: 'Product descriprtion versions',
    },
    packshots_descriptions_version: {
        id: 'packshotz.descriptions.version',
        defaultMessage: 'Version',
        description: 'Packshot descriprtion version',
    },

    packshots_descriptions_versions: {
        id: 'packshotz.descriptions.versions',
        defaultMessage: 'Versions',
        description: 'Packshot descriprtion versions',
    },

    products_buttons_addnew: {
        id: 'products.buttons.addnew',
        defaultMessage: 'New',
        description: '',
    },

    products_buttons_edit: {
        id: 'products.buttons.edit',
        defaultMessage: 'Open',
        description: '',
    },
    products_buttons_view: {
        id: 'products.buttons.view',
        defaultMessage: 'View',
        description: '',
    },
    products_buttons_more: {
        id: 'products.buttons.more',
        defaultMessage: 'More',
        description: '',
    },
    products_buttons_share: {
        id: 'products.buttons.share',
        defaultMessage: 'Share',
        description: '',
    },
    products_buttons_view_all_versions: {
        id: 'products.buttons.view_all_versions',
        defaultMessage: 'View all versions',
        description: '',
    },
    products_buttons_show_packshots: {
        id: 'products.buttons.show_packshots',
        defaultMessage: 'Show packshots',
        description: '',
    },
    products_buttons_duplicate: {
        id: 'products.buttons.duplicate',
        defaultMessage: 'Duplicate',
        description: '',
    },
    products_buttons_move: {
        id: 'products.buttons.move',
        defaultMessage: 'Move',
        description: '',
    },
    products_buttons_rename: {
        id: 'products.buttons.rename',
        defaultMessage: 'Rename',
        description: '',
    },
    products_buttons_delete: {
        id: 'products.buttons.delete',
        defaultMessage: 'Delete',
        description: '',
    },
    products_filter_clients: {
        id: 'products.filter.clients',
        defaultMessage: 'Clients',
        description: '',
    },
    products_filter_open_filter: {
        id: 'products.filter.open_filter',
        defaultMessage: 'Open filter',
        description: '',
    },
    products_filter_close_filter: {
        id: 'products.filter.close_filter',
        defaultMessage: 'Close filter',
        description: '',
    },
    products_filter_brands: {
        id: 'products.filter.brands',
        defaultMessage: 'Brands',
        description: '',
    },
    products_filter_editions: {
        id: 'products.filter.editions',
        defaultMessage: 'Editions',
        description: '',
    },
    products_filter_producttypes: {
        id: 'products.filter.producttypes',
        defaultMessage: 'Product types',
        description: '',
    },
    products_filter_sizes: {
        id: 'products.filter.sizes',
        defaultMessage: 'Sizes',
        description: '',
    },
    products_filter_modal_groups_client_add_title: {
        id: 'products.filter.modal.groups.client.add.title',
        defaultMessage: 'Add new client',
        description: '',
    },
    products_filter_modal_groups_brand_add_title: {
        id: 'products.filter.modal.groups.brand.add.title',
        defaultMessage: 'Add new brand',
        description: '',
    },
    products_filter_modal_groups_edition_add_title: {
        id: 'products.filter.modal.groups.edition.add.title',
        defaultMessage: 'Add new edition',
        description: '',
    },
    products_filter_modal_groups_button_show_results: {
        id: 'products.filter.modal.groups.button.show_results',
        defaultMessage: 'Show results',
        description: '',
    },
    products_filter_modal_groups_button_clear_filter: {
        id: 'products.filter.modal.groups.button.clear_filter',
        defaultMessage: 'Clear filter',
        description: '',
    },
    products_modal_share_title: {
        id: 'products.modal.share.title',
        defaultMessage: 'Share',
        description: '',
    },
    products_modal_share_product_title: {
        id: 'products.modal.share.product.title',
        defaultMessage: 'Share product',
        description: '',
    },
    products_modal_share_version_tab: {
        id: 'products.modal.share.version.tab',
        defaultMessage: 'Version',
        description: '',
    },
    products_modal_share_product_tab: {
        id: 'products.modal.share.product.tab',
        defaultMessage: 'Product',
        description: '',
    },
    products_modal_share_description_current: {
        id: 'products.modal.share.description.current',
        defaultMessage: 'Current version',
        description: '',
    },
    products_modal_share_description_all_versions: {
        id: 'products.modal.share.description.all.versions',
        defaultMessage: 'Current version',
        description: '',
    },
    products_modal_share_description_of: {
        id: 'products.modal.share.description_of',
        defaultMessage: 'of',
        description: '',
    },
    products_modal_share_description_is_shared: {
        id: 'products.modal.share.description_is_shared',
        defaultMessage: 'is shared with following users:',
        description: '',
    },
    products_modal_share_description_are_shared: {
        id: 'products.modal.share.description_are_shared',
        defaultMessage: 'are shared with following users:',
        description: '',
    },
    products_modal_share_input_placeholder: {
        id: 'products.modal.share.input.placeholder',
        defaultMessage: 'Enter name or e-mail',
        description: '',
    },
    products_modal_share_message_title: {
        id: 'products.modal.share.message.title',
        defaultMessage: 'Message',
        description: '',
    },
    products_modal_share_add_invite_message: {
        id: 'products.modal.share.add.invite.message',
        defaultMessage: 'Add invite message',
        description: '',
    },
    products_modal_share_title_team: {
        id: 'products.modal.share.title.team',
        defaultMessage: 'Team',
        description: '',
    },
    products_modal_share_title_others: {
        id: 'products.modal.share.title.others',
        defaultMessage: 'Others',
        description: '',
    },
    products_modal_share_roles_viewer: {
        id: 'products.modal.share.roles.viewer',
        defaultMessage: 'Viewer',
        description: '',
    },
    products_modal_share_roles_editor: {
        id: 'products.modal.share.roles.editor',
        defaultMessage: 'Editor',
        description: '',
    },
    products_modal_share_roles_commenter: {
        id: 'products.modal.share.roles.commenter',
        defaultMessage: 'Commenter',
        description: '',
    },
    products_modal_share_team_roles_owner: {
        id: 'products.modal.share.team.roles.owner',
        defaultMessage: 'Default (Owner)',
        description: '',
    },
    products_modal_share_team_roles_none: {
        id: 'products.modal.share.team.roles.none',
        defaultMessage: 'Default (Not shared)',
        description: '',
    },
    products_modal_share_roles_none: {
        id: 'products.modal.share.roles.none',
        defaultMessage: 'Default (Not shared)',
        description: '',
    },
    products_modal_share_team_roles_viewer: {
        id: 'products.modal.share.team.roles.viewer',
        defaultMessage: 'Default (Viewer)',
        description: '',
    },
    products_modal_share_team_roles_commenter: {
        id: 'products.modal.share.team.roles.commenter',
        defaultMessage: 'Default (Commenter)',
        description: '',
    },
    products_modal_share_team_roles_editor: {
        id: 'products.modal.share.team.roles.editor',
        defaultMessage: 'Default (Editor)',
        description: '',
    },
    products_modal_share_roles_owner: {
        id: 'products.modal.share.roles.owner',
        defaultMessage: 'Owner',
        description: '',
    },
    products_modal_share_roles_default: {
        id: 'products.modal.share.roles.default',
        defaultMessage: 'Default',
        description: '',
    },
    products_modal_share_roles_add_ownership: {
        id: 'products.modal.share.roles.add_ownership',
        defaultMessage: 'Add Ownership',
        description: '',
    },
    products_modal_share_roles_remove_access: {
        id: 'products.modal.share.roles.remove_access',
        defaultMessage: 'Remove',
        description: '',
    },
    products_modal_share_title_public_link: {
        id: 'products.modal.share.title.public_link',
        defaultMessage: 'Public Link',
        description: '',
    },
    products_modal_share_title_website_embed: {
        id: 'products.modal.share.title.website_embed',
        defaultMessage: 'Embed on your website',
        description: '',
    },
    products_modal_share_description_public_link_disabled: {
        id: 'products.modal.share.description.public_link_disabled',
        defaultMessage: 'Public Link is disabled',
        description: '',
    },
    products_modal_share_button_enabled: {
        id: 'products.modal.share.button.enabled',
        defaultMessage: 'Enabled',
        description: '',
    },

    products_modal_share_button_disabled: {
        id: 'products.modal.share.button.disabled',
        defaultMessage: 'Disabled',
        description: '',
    },
    products_modal_share_button_done: {
        id: 'products.modal.share.button.done',
        defaultMessage: 'Done',
        description: '',
    },
    products_modal_share_button_cancel: {
        id: 'products.modal.share.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_share_button_share: {
        id: 'products.modal.share.button.share',
        defaultMessage: 'Share',
        description: '',
    },
    products_modal_share_description_link_view: {
        id: 'products.modal.share.description.link_view',
        defaultMessage: 'Anyone with this link can view',
        description: '',
    },
    products_modal_share_button_close: {
        id: 'products.modal.share.button.close',
        defaultMessage: 'Close',
        description: '',
    },
    products_modal_duplicate_title: {
        id: 'products.modal.duplicate.title',
        defaultMessage: 'Duplicate',
        description: '',
    },
    products_modal_duplicate_description: {
        id: 'products.modal.duplicate.description',
        defaultMessage: 'Select the version that will be duplicated as a new product',
        description: '',
    },
    products_modal_duplicate_input_description: {
        id: 'products.modal.duplicate.description',
        defaultMessage: 'Enter the new product’s name',
        description: '',
    },
    products_modal_duplicate_description_version: {
        id: 'products.modal.duplicate.description.version',
        defaultMessage: 'Version',
        description: '',
    },
    products_modal_duplicate_button_cancel: {
        id: 'products.modal.duplicate.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_duplicate_success_text: {
        id: 'products.modal.duplicate.success.text',
        defaultMessage: 'The product was successfully duplicated as',
        description: '',
    },
    products_modal_duplicate_button_close: {
        id: 'products.modal.duplicate.button.close',
        defaultMessage: 'Close',
        description: '',
    },
    products_modal_duplicate_button_edit: {
        id: 'products.modal.duplicate.button.edit',
        defaultMessage: 'Edit',
        description: '',
    },
    products_modal_duplicate_button_duplicate: {
        id: 'products.modal.duplicate.button.duplicate',
        defaultMessage: 'Duplicate',
        description: '',
    },
    products_modal_move_title: {
        id: 'products.modal.move.title',
        defaultMessage: 'Move',
        description: '',
    },
    products_modal_move_description: {
        id: 'products.modal.move.description',
        defaultMessage: 'Select your move destination',
        description: '',
    },
    products_modal_move_button_add_new_client: {
        id: 'products.modal.move.button.add_new_client',
        defaultMessage: 'Add new client',
        description: '',
    },
    products_modal_move_button_cancel: {
        id: 'products.modal.move.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_move_button_move: {
        id: 'products.modal.move.button.move',
        defaultMessage: 'Move',
        description: '',
    },
    products_modal_groups_client_add_title: {
        id: 'products.modal.groups.client.add.title',
        defaultMessage: 'Add new client',
        description: '',
    },
    products_modal_groups_brand_add_title: {
        id: 'products.modal.groups.brand.add.title',
        defaultMessage: 'Add new brand',
        description: '',
    },
    products_modal_groups_edition_add_title: {
        id: 'products.modal.groups.edition.add.title',
        defaultMessage: 'Add new edition',
        description: '',
    },
    products_modal_groups_client_select_add_title: {
        id: 'products.modal.groups.client_select.add.title',
        defaultMessage: 'Client',
        description: '',
    },
    products_modal_groups_brand_select_add_title: {
        id: 'products.modal.groups.brand_select.add.title',
        defaultMessage: 'Brand',
        description: '',
    },
    products_modal_groups_add_input_name_title: {
        id: 'products.modal.groups.add.input.name.title',
        defaultMessage: 'Name',
        description: '',
    },
    products_modal_groups_add_icon_title: {
        id: 'products.modal.groups.add.icon.title',
        defaultMessage: 'Upload Icon',
        description: '',
    },
    products_modal_groups_add_color_description: {
        id: 'products.modal.groups.add.color.description',
        defaultMessage: 'or choose color',
        description: '',
    },
    products_modal_groups_add_button_cancel: {
        id: 'products.modal.groups.add.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_groups_add_button_add: {
        id: 'products.modal.groups.add.button.add',
        defaultMessage: 'Add',
        description: '',
    },
    products_modal_groups_edit_title: {
        id: 'products.modal.groups.edit.title',
        defaultMessage: 'Edit',
        description: '',
    },
    products_modal_groups_edit_no_client_title: {
        id: 'products.modal.groups.edit.no.client.title',
        defaultMessage: 'No client',
        description: '',
    },
    dialog_groups_select_client: {
        id: 'dialog.groups.select.client',
        defaultMessage: 'Select client',
        description: '',
    },
    dialog_groups_select_brand: {
        id: 'dialog.groups.select.brand',
        defaultMessage: 'Select brand',
        description: '',
    },
    dialog_groups_select_search: {
        id: 'dialog.groups.select.search',
        defaultMessage: 'Search...',
        description: '',
    },
    dialog_groups_create_client: {
        id: 'dialog.groups.create.client',
        defaultMessage: 'Create a new client...',
        description: '',
    },
    dialog_groups_button_create: {
        id: 'dialog.groups.button.create',
        defaultMessage: 'Create',
        description: '',
    },
    dialog_groups_button_cancel: {
        id: 'dialog.groups.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    dialog_groups_button_select: {
        id: 'dialog.groups.button.select',
        defaultMessage: 'Select',
        description: '',
    },

    dialog_groups_create_brand: {
        id: 'dialog.groups.create.brand',
        defaultMessage: 'Create a new brand...',
        description: '',
    },
    dialog_groups_create_edition: {
        id: 'dialog.groups.create.edition',
        defaultMessage: 'Create a new edition...',
        description: '',
    },

    dialog_groups_select_edition: {
        id: 'dialog.groups.select.edition',
        defaultMessage: 'Select edition',
        description: '',
    },
    install_plugin: {
        id: 'install_plugin',
        defaultMessage: 'Install our Adobe Plugin',
        description: '',
    },
    products_modal_groups_edit_no_brand_title: {
        id: 'products.modal.groups.edit.no.brand.title',
        defaultMessage: 'No brand',
        description: '',
    },
    products_modal_groups_edit_client_title: {
        id: 'products.modal.groups.edit.client.title',
        defaultMessage: 'Client',
        description: '',
    },

    packshots_modal_close: {
        id: 'packshots.modal.close',
        defaultMessage: 'Close',
        description: '',
    },


    front_view_headline: {
        id: 'front_view_headline',
        defaultMessage: 'Front View',
        description: '',
    },
    front_with_drops_view_headline: {
        id: 'front_with_drops_view_headline',
        defaultMessage: 'Front View with drops',
        description: '',
    },

    top_with_drops_view_headline: {
        id: 'top_with_drops_view_headline',
        defaultMessage: 'Top View with drops',
        description: '',
    },
    hero_view_headline: {
        id: 'hero_view_headline',
        defaultMessage: 'Top View with drops',
        description: '',
    },
    hero_with_drops_view_headline: {
    id: 'hero_with_drops_view_headline',
        defaultMessage: 'Top View with drops',
        description: '',
    },
    top_view_headline: {
        id: 'top_view_headline',
            defaultMessage: 'Top View with drops',
            description: '',
    },

    products_modal_groups_edit_brand_title: {
        id: 'products.modal.groups.edit.brand.title',
        defaultMessage: 'Brand',
        description: '',
    },
    products_modal_groups_edit_edition_title: {
        id: 'products.modal.groups.edit.edition.title',
        defaultMessage: 'Edition',
        description: '',
    },
    products_modal_groups_edit_create_product: {
        id: 'products.modal.groups.edit.create.product',
        defaultMessage: 'Create product',
        description: '',
    },

    products_modal_groups_edit_input_name_title: {
        id: 'products.modal.groups.edit.input.name.title',
        defaultMessage: 'Name',
        description: '',
    },
    products_modal_groups_edit_icon_title: {
        id: 'products.modal.groups.edit.icon.title',
        defaultMessage: 'Upload Icon',
        description: '',
    },
    products_modal_groups_edit_upload_description: {
        id: 'products.modal.groups.edit.upload.description',
        defaultMessage: 'Drop your file here or click to',
        description: '',
    },
    products_modal_groups_edit_upload_choose_file: {
        id: 'products.modal.groups.edit.upload.choose_file',
        defaultMessage: 'Choose file...',
        description: '',
    },
    products_modal_groups_edit_color_description: {
        id: 'products.modal.groups.edit.color.description',
        defaultMessage: 'or choose color',
        description: '',
    },
    products_modal_groups_edit_button_cancel: {
        id: 'products.modal.groups.edit.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_groups_edit_button_save: {
        id: 'products.modal.groups.edit.button.save',
        defaultMessage: 'Save',
        description: '',
    },
    products_modal_delete_title: {
        id: 'products.modal.delete.title',
        defaultMessage: 'Delete',
        description: '',
    },
    products_modal_delete_description: {
        id: 'products.modal.delete.description',
        defaultMessage: 'Are you sure you wanna delete ?',
        description: '',
    },
    products_modal_delete_button_cancel: {
        id: 'products.modal.delete.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_delete_confirm: {
        id: 'products.modal.delete.confirm',
        defaultMessage: 'Deleting by this product you will affect an {deleteParent}, are you sure you want to delete ?',
        description: '',
    },
    products_modal_delete_button_confirm: {
        id: 'products.modal.delete.button.confirm',
        defaultMessage: 'Delete anyway',
        description: '',
    },
    products_modal_delete_button_delete: {
        id: 'products.modal.delete.button.delete',
        defaultMessage: 'Delete',
        description: '',
    },
    products_modal_rename_title: {
        id: 'products.modal.rename.title',
        defaultMessage: 'Rename',
        description: '',
    },
    products_modal_rename_button_cancel: {
        id: 'products.modal.rename.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    products_modal_rename_button_rename: {
        id: 'products.modal.rename.button.rename',
        defaultMessage: 'Rename',
        description: '',
    },
    main_menu_title: {
        id: 'main_menu.title',
        defaultMessage: 'Menu',
        description: '',
    },
    main_menu_title_me: {
        id: 'main_menu.title.me',
        defaultMessage: 'Me',
        description: '',
    },
    main_menu_title_team: {
        id: 'main_menu.title.team',
        defaultMessage: 'Team',
        description: '',
    },
    main_menu_title_help: {
        id: 'main_menu.title.help',
        defaultMessage: 'Help',
        description: '',
    },
    main_menu_items_basic_plan: {
        id: 'main_menu.items.basic_plan',
        defaultMessage: 'Explorer plan',
        description: '',
    },
    main_menu_items_external_plan: {
        id: 'main_menu.items.external_plan',
        defaultMessage: 'External',
        description: '',
    },
    main_menu_items_credits: {
        id: 'main_menu.items.credits',
        defaultMessage: 'Credits',
        description: '',
    },
    main_menu_items_my_account: {
        id: 'main_menu.items.my_account',
        defaultMessage: 'My Account',
        description: '',
    },
    main_menu_items_sign_out: {
        id: 'main_menu.items.sign_out',
        defaultMessage: 'Sign Out',
        description: '',
    },
    main_menu_items_purchase_credits: {
        id: 'main_menu.items.purchase_credits',
        defaultMessage: 'Purchase Credits',
        description: '',
    },
    main_menu_items_upgrade: {
        id: 'main_menu.items.upgrade',
        defaultMessage: 'Upgrade',
        description: '',
    },
    main_menu_items_switch_teams: {
        id: 'main_menu.items.switch_teams',
        defaultMessage: 'Switch Teams',
        description: '',
    },
    main_menu_items_faq_help: {
        id: 'main_menu.items.faq_help',
        defaultMessage: 'FAQ / Help',
        description: '',
    },
    main_menu_items_contact_support: {
        id: 'main_menu.items.contact_support',
        defaultMessage: 'Contact support',
        description: '',
    },
    main_menu_breadcrumbs_version: {
        id: 'main_menu.breadcrumbs.version',
        defaultMessage: 'Version',
        description: '',
    },
    main_menu_button_render_packshot: {
        id: 'main_menu.button.render_packshot',
        defaultMessage: 'Packshots',
        description: '',
    },
    main_menu_button_share: {
        id: 'main_menu.button.share',
        defaultMessage: 'Share',
        description: '',
    },
    main_menu_notification_button_accept : {
        id: 'main_menu.notification.button.accept',
        defaultMessage: 'Accept',
        description: '',
    },
    main_menu_notification_button_reject : {
        id: 'main_menu.notification.button.reject',
        defaultMessage: 'Reject',
        description: '',
    },
    main_menu_no_notification_title : {
        id: 'main_menu.no.notification.title',
        defaultMessage: 'No notifications',
        description: '',
    },
    side_menu_items_profile: {
        id: 'side_menu.items.profile',
        defaultMessage: 'Profile',
        description: '',
    },
    side_menu_items_security: {
        id: 'side_menu.items.security',
        defaultMessage: 'Security',
        description: '',
    },
    side_menu_items_team: {
        id: 'side_menu.items.team',
        defaultMessage: 'Team',
        description: '',
    },
    side_menu_items_members: {
        id: 'side_menu.items.members',
        defaultMessage: 'Members',
        description: '',
    },
    side_menu_items_billing: {
        id: 'side_menu.items.billing',
        defaultMessage: 'Billing',
        description: '',
    },
    side_menu_items_payments_credits: {
        id: 'side_menu.items.payments_credits',
        defaultMessage: 'Payments & Credits',
        description: '',
    },
    side_menu_items_subscription: {
        id: 'side_menu.items.subscription',
        defaultMessage: 'Subscription',
        description: '',
    },
    side_menu_cookies: {
        id: 'side_menu.cookies',
        defaultMessage: 'Cookies Preferences',
        description: '',
    },
    side_menu_items_switch_teams: {
        id: 'side_menu.items.switch_teams',
        defaultMessage: 'Switch Teams',
        description: '',
    },
    modal_switch_team_button_create_new_team: {
        id: 'modal.switch_team.button.create_new_team',
        defaultMessage: 'Create New Team',
        description: '',
    },
    profile_title: {
        id: 'profile.title',
        defaultMessage: 'Profile',
        description: '',
    },
    profile_form_title_name: {
        id: 'profile.form.title.name',
        defaultMessage: 'Name',
        description: '',
    },
    profile_form_title_name_placeholder: {
        id: 'profile.form.title.name.placeholder',
        defaultMessage: 'Enter your name',
        description: '',
    },
    profile_form_title_surname: {
        id: 'profile.form.title.surname',
        defaultMessage: 'Surname',
        description: '',
    },
    profile_form_title_surname_placeholder: {
        id: 'profile.form.title.surname.placeholder',
        defaultMessage: 'Enter your surname',
        description: '',
    },
    profile_form_title_e_mail: {
        id: 'profile.form.title.e_mail',
        defaultMessage: 'E-mail',
        description: '',
    },
    profile_form_title_phone: {
        id: 'profile.form.title.phone',
        defaultMessage: 'Phone',
        description: '',
    },
    profile_form_title_phone_placeholder: {
        id: 'profile.form.title.phone.placeholder',
        defaultMessage: 'Enter your phone',
        description: '',
    },
    profile_form_title_country: {
        id: 'profile.form.title.country',
        defaultMessage: 'Country',
        description: '',
    },
    profile_form_title_country_placeholder: {
        id: 'profile.form.title.country.placeholder',
        defaultMessage: 'Enter your country',
        description: '',
    },
    profile_form_title_timezone: {
        id: 'profile.form.title.timezone',
        defaultMessage: 'Timezone',
        description: '',
    },
    profile_form_title_newsletter: {
        id: 'profile.form.title.newsletter',
        defaultMessage: 'Newsletter',
        description: '',
    },
    profile_form_button_save_changes: {
        id: 'profile.form.button.save_changes',
        defaultMessage: 'Save changes',
        description: '',
    },
    profile_form_button_delete_account: {
        id: 'profile.form.button.delete_account',
        defaultMessage: 'Delete account',
        description: '',
    },
    profile_form_title_preferred_language: {
        id: 'profile.form.title.preferred_language',
        defaultMessage: 'Preferred language',
        description: '',
    },
    profile_splitter_danger_zone_title: {
        id: 'profile.splitter.danger_zone.title',
        defaultMessage: 'Danger Zone',
        description: '',
    },
    security_title: {
        id: 'security.title',
        defaultMessage: 'Security',
        description: '',
    },
    security_form_lost_password: {
        id: 'security.form.lost_password',
        defaultMessage: 'Lost Password ?',
        description: '',
    },
    security_form_current_password: {
        id: 'security.form.current_password',
        defaultMessage: 'Current Password',
        description: '',
    },
    security_form_current_password_placeholder: {
        id: 'security.form.current_password.placeholder',
        defaultMessage: 'Enter your current password',
        description: '',
    },
    security_form_new_password: {
        id: 'security.form.new_password',
        defaultMessage: 'New Password',
        description: '',
    },
    security_form_new_password_placeholder: {
        id: 'security.form.new_password.placeholder',
        defaultMessage: 'Enter your New Password',
        description: '',
    },
    security_form_verify_new_password: {
        id: 'security.form.verify_new_password',
        defaultMessage: 'Verify New Password',
        description: '',
    },
    security_form_verify_new_password_placeholder: {
        id: 'security.form.verify_new_password.placeholder',
        defaultMessage: 'Enter your new password again',
        description: '',
    },
    security_form_button_save_new_password: {
        id: 'security.form.button.save_new_password',
        defaultMessage: 'Save New Password',
        description: '',
    },
    security_splitter_connected_accounts_title: {
        id: 'security.splitter.connected_accounts.title',
        defaultMessage: 'Connected Accounts',
        description: '',
    },
    security_buttons_sso_connect: {
        id: 'security.buttons.sso.connect',
        defaultMessage: 'Connect',
        description: '',
    },
    security_buttons_sso_connected: {
        id: 'security.buttons.sso.connected',
        defaultMessage: 'Connected',
        description: '',
    },
    security_buttons_sso_unlink: {
        id: 'security.buttons.sso.unlink',
        defaultMessage: 'Unlink',
        description: '',
    },
    team_title: {
        id: 'team.title',
        defaultMessage: 'Team',
        description: '',
    },
    team_form_team_name: {
        id: 'team.form.team_name',
        defaultMessage: 'Team Name',
        description: '',
    },
    team_form_team_name_placeholder: {
        id: 'team.form.team_name.placeholder',
        defaultMessage: 'Enter the name of your organization',
        description: '',
    },
    team_form_team_e_mail_placeholder: {
        id: 'team.form.team_e_mail.placeholder',
        defaultMessage: 'Enter the main contact e-mail',
        description: '',
    },
    team_form_team_phone_placeholder: {
        id: 'team.form.team_phone.placeholder',
        defaultMessage: 'Enter the main contact phone number',
        description: '',
    },
    team_form_team_country_placeholder: {
        id: 'team.form.team_country.placeholder',
        defaultMessage: 'Enter your country',
        description: '',
    },
    team_form_e_mail: {
        id: 'team.form.e_mail',
        defaultMessage: 'E-mail',
        description: '',
    },
    team_form_phone: {
        id: 'team.form.phone',
        defaultMessage: 'Phone',
        description: '',
    },
    team_form_country: {
        id: 'team.form.country',
        defaultMessage: 'Country',
        description: '',
    },
    team_form_button_save_changes: {
        id: 'team.form.button.save_changes',
        defaultMessage: 'Save Changes',
        description: '',
    },
    team_form_tip: {
        id: 'team.form.tip',
        defaultMessage: 'Tip: You can set your billing details here.',
        description: '',
    },
    team_splitter_danger_zone_title: {
        id: 'team.splitter.danger_zone.title',
        defaultMessage: 'Danger Zone',
        description: '',
    },
    team_form_button_delete_team: {
        id: 'team.form.button.delete_team',
        defaultMessage: 'Delete Team',
        description: '',
    },
    team_form_button_leave_team: {
        id: 'team.form.button.leave_team',
        defaultMessage: 'Leave Team',
        description: '',
    },
    team_form_preferred_currency: {
        id: 'team.form.preferred_currency',
        defaultMessage: 'Preferred Currency',
        description: '',
    },

    members_title: {
        id: 'members.title',
        defaultMessage: 'Members',
        description: '',
    },
    members_description: {
        id: 'members.description',
        defaultMessage: 'You are on the plan {currentPlan} and using {countMembers} of {countSeats} paid seats.',
        description: '',
    },
    members_need_more: {
        id: 'members.need_more',
        defaultMessage: 'Need more ?',
        description: '',
    },
    members_table_title_member: {
        id: 'members.table.title.member',
        defaultMessage: 'MEMBER',
        description: '',
    },
    members_table_title_date: {
        id: 'members.table.title.date',
        defaultMessage: 'DATE',
        description: '',
    },
    members_table_title_status: {
        id: 'members.table.title.status',
        defaultMessage: 'STATUS',
        description: '',
    },
    members_table_title_role: {
        id: 'members.table.title.role',
        defaultMessage: 'ROLE',
        description: '',
    },
    members_button_invite_member: {
        id: 'members.button.invite_member',
        defaultMessage: 'Invite Member',
        description: '',
    },
    members_table_status_active: {
        id: 'members.table.status.active',
        defaultMessage: 'Active',
        description: '',
    },
    members_table_status_inactive: {
        id: 'members.table.status.inactive',
        defaultMessage: 'Inactive',
        description: '',
    },
    members_table_role_owner: {
        id: 'members.table.role.owner',
        defaultMessage: 'Owner',
        description: '',
    },
    members_table_role_admin: {
        id: 'members.table.role.admin',
        defaultMessage: 'Admin',
        description: '',
    },
    members_table_role_user: {
        id: 'members.table.role.user',
        defaultMessage: 'User',
        description: '',
    },
    members_table_role_billing: {
        id: 'members.table.role.billing',
        defaultMessage: 'Billing',
        description: '',
    },
    members_table_role_remove_user: {
        id: 'members.table.role.remove_user',
        defaultMessage: 'Remove user',
        description: '',
    },
    members_table_role_invited: {
        id: 'members.table.role.invited',
        defaultMessage: 'Invited',
        description: '',
    },
    members_table_role_invite_again: {
        id: 'members.table.role.invite_again',
        defaultMessage: 'Invite again',
        description: '',
    },
    billing_title: {
        id: 'billing.title',
        defaultMessage: 'Billing',
        description: '',
    },
    billing_description_main: {
        id: 'billing.description.main',
        defaultMessage: 'You are on the {currentPeriod} {currentPlan} plan, your subscription for {currentSeats} users will renew on {recurringDate} {recurringPrice}.',
        description: '',
    },
    billing_description_end: {
        id: 'billing.description.end',
        defaultMessage: 'You are on the {currentPeriod} {currentPlan} plan, your subscription for {currentSeats} users will end on {endDate}.',
        description: '',
    },
    billing_description_main_free: {
        id: 'billing.description.main_free',
        defaultMessage: 'You are on the Free plan, forever.',
        description: '',
    },
    billing_description_main_inactive: {
        id: 'billing.description.main_inactive',
        defaultMessage: 'You are on the {currentPlan} plan, your subscription for {currentSeats} users will end on {recurringDate}.',
        description: '',
    },
    billing_description_free_main: {
        id: 'billing.description.free.main',
        defaultMessage: 'You are on the {currentPlan} plan for 1 user.',
        description: '',
    },
    billing_description_plan: {
        id: 'billing.description.plan',
        defaultMessage: 'Subscription plan and number of seats can be changed here.',
        description: '',
    },
    billing_description_payments: {
        id: 'billing.description.payments',
        defaultMessage: 'Payments history and invoices are here.\nYou have {currentCredits} credits, including {monthlyCredits} monthly bonus credits.\n Additional credits can be purchased here.',
        description: '',
    },
    billing_splitter_billing_information: {
        id: 'billing.splitter.billing_information',
        defaultMessage: 'Billing Information',
        description: '',
    },
    billing_form_organization_name: {
        id: 'billing.form.organization_name',
        defaultMessage: 'Organization Name',
        description: '',
    },
    billing_form_organization_name_placeholder: {
        id: 'billing.form.organization_name.placeholder',
        defaultMessage: 'Enter company or your name',
        description: '',
    },
    billing_form_address_line1: {
        id: 'billing.form.address_line1',
        defaultMessage: 'Address Line 1',
        description: '',
    },
    billing_form_address_line1_placeholder: {
        id: 'billing.form.address_line1.placeholder',
        defaultMessage: 'Enter your address',
        description: '',
    },
    billing_form_address_line2: {
        id: 'billing.form.address_line2',
        defaultMessage: 'Address Line 2',
        description: '',
    },
    billing_form_address_line2_placeholder: {
        id: 'billing.form.address_line2.placeholder',
        defaultMessage: 'Enter your address 2',
        description: '',
    },
    billing_form_city: {
        id: 'billing.form.city',
        defaultMessage: 'City',
        description: '',
    },
    billing_form_city_placeholder: {
        id: 'billing.form.city.placeholder',
        defaultMessage: 'Enter your city',
        description: '',
    },
    billing_form_postal_code: {
        id: 'billing.form.postal_code',
        defaultMessage: 'Postal Code',
        description: '',
    },
    billing_form_postal_code_placeholder: {
        id: 'billing.form.postal_code.placeholder',
        defaultMessage: 'ZIP',
        description: '',
    },
    billing_form_country: {
        id: 'billing.form.country',
        defaultMessage: 'Country',
        description: '',
    },
    billing_form_country_placeholder: {
        id: 'billing.form.country.placeholder',
        defaultMessage: 'Enter your country',
        description: '',
    },
    billing_form_state: {
        id: 'billing.form.state',
        defaultMessage: 'State',
        description: '',
    },
    billing_form_state_placeholder: {
        id: 'billing.form.state.placeholder',
        defaultMessage: 'Enter your state',
        description: '',
    },
    billing_form_vat_id: {
        id: 'billing.form.vat_id',
        defaultMessage: 'Vat ID',
        description: '',
    },
    billing_form_vat_id_placeholder: {
        id: 'billing.form.vat_id.placeholder',
        defaultMessage: 'Enter your tax ID',
        description: '',
    },
    billing_button_save_changes: {
        id: 'billing.button.save_changes',
        defaultMessage: 'Save Changes',
        description: '',
    },
    billing_splitter_billing_contacts: {
        id: 'billing.splitter.billing_contacts',
        defaultMessage: 'Billing Contacts',
        description: '',
    },
    billing_button_add_billing_contact: {
        id: 'billing.button.add_billing_contact',
        defaultMessage: 'Add Billing Contacts',
        description: '',
    },
    billing_contact_invoices: {
        id: 'billing.contact.invoices',
        defaultMessage: 'Invoices are always send to main contact e-mail. It can be changed here.',
        description: '',
    },
    billing_contact_button_remove: {
        id: 'billing.contact.button.remove',
        defaultMessage: 'Remove',
        description: '',
    },
    payments_credits_title: {
        id: 'payments_credits.title',
        defaultMessage: 'Payments & Credits',
        description: '',
    },
    payments_credits_buy_credits: {
        id: 'payments_credits.buy_credits',
        defaultMessage: 'Buy Credits',
        description: '',
    },
    payments_credits_balance_total: {
        id: 'payments_credits.balance.total',
        defaultMessage: 'Current credits balance',
        description: '',
    },
    payments_credits_table_date: {
        id: 'payments_credits.table.date',
        defaultMessage: 'DATE',
        description: '',
    },
    payments_credits_table_activity: {
        id: 'payments_credits.table.activity',
        defaultMessage: 'Activity',
        description: '',
    },
    payments_credits_table_payment: {
        id: 'payments_credits.table.payment',
        defaultMessage: 'Payment',
        description: '',
    },
    payments_credits_table_credits: {
        id: 'payments_credits.table.credits',
        defaultMessage: 'Credits',
        description: '',
    },
    payments_credits_table_credits_balance: {
        id: 'payments_credits.table.credits_balance',
        defaultMessage: 'Credits balance',
        description: '',
    },
    payments_credits_table_credit_bundle_title: {
        id: 'payments_credits.table.credit_bundle.title',
        defaultMessage: 'Obalero Credits',
        description: '',
    },
    payments_credits_table_subscription_title: {
        id: 'payments_credits.table.subscription.title',
        defaultMessage: 'Subscription',
        description: '',
    },
    payments_credits_no_transaction: {
        id: 'payments_credits.no.transaction',
        defaultMessage: 'There are no transactions yet',
        description: '',
    },
    payments_credits_packshot_render: {
        id: 'payments_credits.packshot_render',
        defaultMessage: 'Packshot',
        description: '',
    },

    payments_credits_packshot_renders_plu: {
        id: 'payments_credits.packshot_renders_plu',
        defaultMessage: 'Packshots',
        description: '',
    },
    payments_credits_packshot_renders_plu_5: {
        id: 'payments_credits.packshot_renders_plu_5',
        defaultMessage: 'Packshotů',
        description: '',
    },
    payments_credits_table_credit_bundle_description: {
        id: 'payments_credits.table.credit_bundle.description',
        defaultMessage: 'Pack of {credits} Obalero Credits. Those credits never expires.',
        description: '',
    },
    payments_credits_table_credit_bundle_description_download: {
        id: 'payments_credits.table.credit_bundle.description_download',
        defaultMessage: 'Download your invoice here.',
        description: '',
    },

    payments_credits_table_subscription_description: {
        id: 'payments_credits.table.subscription.description',
        defaultMessage: 'Monthly subscription for {users} users. Added {bonusCredits} bonus credits. ',
        description: '',
    },
    payments_credits_table_subscription_description_download: {
        id: 'payments_credits.table.subscription.description_download',
        defaultMessage: 'Download your invoice here.',
        description: '',
    },

    subscription_button_apply: {
        id: 'subscription.button.apply',
        defaultMessage: 'Apply changes',
        description: '',
    },
    subscription_title: {
        id: 'subscription.title',
        defaultMessage: 'Subscription',
        description: '',
    },
    subscription_licence_for: {
        id: 'subscription.licence_for',
        defaultMessage: 'Licence for',
        description: '',
    },
    subscription_users_billed: {
        id: 'subscription.users_billed',
        defaultMessage: 'users billed',
        description: '',
    },
    subscription_licence_monthly: {
        id: 'subscription.licence.monthly',
        defaultMessage: 'Monthly',
        description: '',
    },
    subscription_licence_yearly: {
        id: 'subscription.licence.yearly',
        defaultMessage: 'Yearly',
        description: '',
    },
    login_title: {
        id: 'login.title',
        defaultMessage: 'Hello, you can log in by followin methods',
        description: '',
    },
    login_or_by_e_mail: {
        id: 'login.or_by_e_mail',
        defaultMessage: 'or by e-mail',
        description: '',
    },
    login_input_placeholder_password: {
        id: 'login.input.placeholder.password',
        defaultMessage: 'Password',
        description: '',
    },
    login_button_continue: {
        id: 'login.button.continue',
        defaultMessage: 'Enter',
        description: '',
    },
    login_register_by_e_mail: {
        id: 'login.register_by_e_mail',
        defaultMessage: 'Don\'t have account ? Register here',
        description: '',
    },
    packshots_estimated_time: {
        id: 'packshots.estimated_time',
        defaultMessage: 'Estimated time - ',
        description: '',
    },
    ////
    modal_alert_title_information: {
        id: 'modal.alert.title.information',
        defaultMessage: 'Information message',
        description: '',
    },
    modal_alert_title_warning: {
        id: 'modal.alert.title.warning',
        defaultMessage: 'Warning message',
        description: '',
    },
    modal_leave_team_title_warning: {
        id: 'modal.leave_team.title.warning',
        defaultMessage: 'Leave team',
        description: '',
    },
    modal_leave_team_description: {
        id: 'modal.leave_team.description',
        defaultMessage: 'Are you sure you want to leave this team ?',
        description: '',
    },
    modal_alert_title_error: {
        id: 'modal.alert.title.error',
        defaultMessage: 'Error message',
        description: '',
    },
    modal_alert_title_subscription_update: {
        id: 'modal.alert.title.subscription_update',
        defaultMessage: 'Subscription update',
        description: '',
    },
    modal_alert_title_subscription_success: {
        id: 'modal.alert.title.subscription_success',
        defaultMessage: 'Update success',
        description: '',
    },
    modal_alert_buttons_layers_yes: {
        id: 'modal.alert.buttons.layers_yes',
        defaultMessage: 'Yes',
        description: '',
    },
    modal_alert_buttons_layers_no: {
        id: 'modal.alert.buttons.layers_no',
        defaultMessage: 'No, I will change layers',
        description: '',
    },
    modal_alert_buttons_delete_with_products: {
        id: 'modal.alert.buttons.delete_with_products',
        defaultMessage: 'Delete with products',
        description: '',
    },
    modal_alert_buttons_delete_without_products: {
        id: 'modal.alert.buttons.delete_without_products',
        defaultMessage: 'Delete without products',
        description: '',
    },
    modal_alert_buttons_no: {
        id: 'modal.alert.buttons.no',
        defaultMessage: 'No',
        description: '',
    },
    modal_alert_buttons_yes: {
        id: 'modal.alert.buttons.yes',
        defaultMessage: 'Yes',
        description: '',
    },
    modal_alert_buttons_ok: {
        id: 'modal.alert.buttons.ok',
        defaultMessage: 'OK',
        description: '',
    },
    modal_alert_buttons_cancel: {
        id: 'modal.alert.buttons.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    ////////////////////////
    modal_render_buttons_cancel: {
        id: 'modal.render.buttons.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_render_buttons_create_packshot: {
        id: 'modal.render.buttons.create_packshot',
        defaultMessage: 'Create packshot',
        description: '',
    },
    ////////////////////////


    modal_register_title: {
        id: 'modal.register.title',
        defaultMessage: 'Register by e-mail',
        description: '',
    },
    modal_register_button_cancel: {
        id: 'modal.register.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_register_button_ok: {
        id: 'modal.register.button.ok',
        defaultMessage: 'Ok',
        description: '',
    },
    modal_register_button_continue: {
        id: 'modal.register.button.continue',
        defaultMessage: 'Continue',
        description: '',
    },
    modal_lost_password_title: {
        id: 'modal.lost_password.title',
        defaultMessage: 'Lost password?',
        description: '',
    },
    modal_lost_password_placeholder: {
        id: 'modal.lost_password.placeholder',
        defaultMessage: 'Enter your e-mail',
        description: '',
    },
    modal_lost_password_cancel: {
        id: 'modal.lost_password.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_lost_password_continue: {
        id: 'modal.lost_password.continue',
        defaultMessage: 'Continue',
        description: '',
    },
    team_select_title: {
        id: 'team.select.title',
        defaultMessage: 'Select your team',
        description: '',
    },
    team_select_button_create_new_team: {
        id: 'team_select.button.create_new_team',
        defaultMessage: 'Create new team',
        description: '',
    },
    terms_and_use_title: {
        id: 'terms_and_use.title',
        defaultMessage: 'Terms & Conditions',
        description: '',
    },
    terms_and_use_button_decline: {
        id: 'terms_and_use.button.decline',
        defaultMessage: 'Decline',
        description: '',
    },
    terms_and_use_button_accept: {
        id: 'terms_and_use.button.accept',
        defaultMessage: 'Accept',
        description: '',
    },

    modal_create_new_team_title: {
        id: 'modal.create_new_team.title',
        defaultMessage: 'Create new team',
        description: '',
    },
    modal_create_new_team_input_placeholder: {
        id: 'modal.create_new_team.input.placeholder',
        defaultMessage: 'Name',
        description: '',
    },
    modal_create_new_team_upload_icon: {
        id: 'modal.create_new_team.upload_icon',
        defaultMessage: 'Upload icon',
        description: '',
    },
    modal_create_new_team_button_cancel: {
        id: 'modal.create_new_team.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_create_new_team_button_save: {
        id: 'modal.create_new_team.button.save',
        defaultMessage: 'Save',
        description: '',
    },
    modal_version_lock_title: {
        id: 'modal.version.lock.title',
        defaultMessage: 'Version lock',
        description: '',
    },
    modal_version_unlock_title: {
        id: 'modal.version.unlock.title',
        defaultMessage: 'Version unlock',
        description: '',
    },
    modal_version_lock_description: {
        id: 'modal.version_lock.description',
        defaultMessage: 'Do you want to lock the current version?',
        description: '',
    },
    modal_version_unlock_description: {
        id: 'modal.version_lock.description',
        defaultMessage: 'Do you want to unlock the current version?',
        description: '',
    },
    modal_version_lock_button_cancel: {
        id: 'modal.version_lock.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_version_lock_button_lock_version: {
        id: 'modal.version_lock.button.lock_version',
        defaultMessage: 'Lock version',
        description: '',
    },
    modal_version_unlock_button_unlock_version: {
        id: 'modal.version_lock.button.lock_version',
        defaultMessage: 'Unlock version',
        description: '',
    },
    modal_new_version_title: {
        id: 'modal.new_version.title',
        defaultMessage: 'New version',
        description: '',
    },
    modal_new_version_description: {
        id: 'modal.new_version.description',
        defaultMessage: 'Do you want to duplicate this version?',
        description: '',
    },
    modal_new_version_button_cancel: {
        id: 'modal.new_version.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_new_version_button_duplicate_version: {
        id: 'modal.new_version.button.duplicate_version',
        defaultMessage: 'Duplicate version',
        description: '',
    },

    modal_add_new_team_member_title_billing: {
        id: 'modal.add_new_team_member.title.billing',
        defaultMessage: 'Add billing contacts',
        description: '',
    },
    modal_add_new_team_member_title_team_members: {
        id: 'modal.add_new_team_member.title.team_members',
        defaultMessage: 'Add new team member',
        description: '',
    },
    modal_add_new_team_member_title_invoices: {
        id: 'modal.add_new_team_member.title.invoices',
        defaultMessage: 'Invoices will be sent to those emails:',
        description: '',
    },
    modal_add_new_team_member_title_warning: {
        id: 'modal.add_new_team_member.title.warning',
        defaultMessage: 'Warning',
        description: '',
    },

    modal_add_new_team_member_button_cancel: {
        id: 'modal.add_new_team_member.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_add_new_team_member_button_add: {
        id: 'modal.add_new_team_member.button.add',
        defaultMessage: 'Add',
        description: '',
    },
    modal_add_new_team_member_button_ok: {
        id: 'modal.add_new_team_member.button.ok',
        defaultMessage: 'Ok',
        description: '',
    },
    modal_add_new_team_member_form_e_mail_placeholder: {
        id:  "modal.add_new_team_member.form.e_mail.placeholder",
        defaultMessage: 'Enter e-mail',
        description: '',
    },
    modal_buy_billing_title: {
        id:  "modal.buy.billing.title",
        defaultMessage: 'Enter your billing info',
        description: '',
    },

    modal_buy_billing_result_cancelled_title: {
        id:  "modal.buy.billing.result_cancelled_title",
        defaultMessage: 'Payment cancelled',
        description: '',
    },
    modal_buy_billing_result_cancelled_message:{
        id:  "modal.buy.billing.result_cancelled_message",
        defaultMessage: 'Your payment was not successful. Please try again.',
        description: '',
    },
    modal_buy_billing_result_permissions_message:{
        id:  "modal.buy.billing.result_permissions_message",
        defaultMessage: 'You dont have permissions',
        description: '',
    },
    modal_buy_billing_result: {
        id:  "modal.buy.billing.result",
        defaultMessage: 'Result of payment',
        description: '',
    },
    modal_buy_credits_title: {
        id:  "modal.buy_credits.title",
        defaultMessage: 'Buy Obalero Credits',
        description: '',
    },
    modal_buy_credits_breadcrumbs_bundle: {
        id:  "modal.buy_credits.breadcrumbs.bundle",
        defaultMessage: 'Pick your bundle',
        description: '',
    },
    modal_buy_credits_breadcrumbs_billing: {
        id:  "modal.buy_credits.breadcrumbs.billing",
        defaultMessage: 'Check your billing info',
        description: '',
    },
    modal_buy_credits_breadcrumbs_pay: {
        id:  "modal.buy_credits.breadcrumbs.pay",
        defaultMessage: 'Pay',
        description: '',
    },
    modal_buy_credits_breadcrumbs_done: {
        id:  "modal.buy_credits.breadcrumbs.done",
        defaultMessage: 'Done',
        description: '',
    },
    modal_buy_credits_card_bundle_title: {
        id:  "modal.buy_credits.card.bundle_title",
        defaultMessage: 'Bundle of {credits} credits',
        description: '',
    },
    modal_buy_credits_card_discount_title: {
        id:  "modal.buy_credits.card.discount_title",
        defaultMessage: '{discount}% discount',
        description: '',
    },

    modal_buy_credits_button_cancel: {
        id:  "modal.buy_credits.button.cancel",
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_buy_credits_button_next: {
        id:  "modal.buy_credits.button.next",
        defaultMessage: 'Next',
        description: '',
    },
    modal_buy_credits_form_organization_name: {
        id:  "modal.buy_credits.form.organization_name",
        defaultMessage: 'Organization Name',
        description: '',
    },
    modal_buy_credits_form_organization_name_placeholder: {
        id:  "modal.buy_credits.form.organization_name.placeholder",
        defaultMessage: 'Enter company or your name',
        description: '',
    },
    modal_buy_credits_form_adress_line_1: {
        id:  "modal.buy_credits.form.adress_line_1",
        defaultMessage: 'Address Line 1',
        description: '',
    },
    modal_buy_credits_form_adress_line_1_placeholder: {
        id:  "modal.buy_credits.form.adress_line_1.placeholder",
        defaultMessage: 'Enter company or your address',
        description: '',
    },
    modal_buy_credits_form_adress_line_2: {
        id:  "modal.buy_credits.form.adress_line_2",
        defaultMessage: 'Address Line 1',
        description: '',
    },
    modal_buy_credits_form_adress_line_2_placeholder: {
        id:  "modal.buy_credits.form.adress_line_2.placeholder",
        defaultMessage: 'Enter company or your address',
        description: '',
    },

    modal_buy_credits_form_city: {
        id:  "modal.buy_credits.form.city",
        defaultMessage: 'City',
        description: '',
    },
    modal_buy_credits_form_city_placeholder: {
        id:  "modal.buy_credits.form.city.placeholder",
        defaultMessage: 'Enter your city',
        description: '',
    },

    modal_buy_credits_form_postal_code: {
        id:  "modal.buy_credits.form.postal_code",
        defaultMessage: 'Postal Code',
        description: '',
    },
    modal_buy_credits_form_postal_code_placeholder: {
        id:  "modal.buy_credits.form.postal_code.placeholder",
        defaultMessage: 'ZIP',
        description: '',
    },

    modal_buy_credits_form_country: {
        id:  "modal.buy_credits.form.country",
        defaultMessage: 'Country',
        description: '',
    },
    modal_buy_credits_form_country_placeholder: {
        id:  "modal.buy_credits.form.country.placeholder",
        defaultMessage: 'Enter your country',
        description: '',
    },
    modal_buy_credits_form_state: {
        id:  "modal.buy_credits.form.state",
        defaultMessage: 'State',
        description: '',
    },

    modal_buy_credits_form_state_placeholder: {
        id:  "modal.buy_credits.form.state.placeholder",
        defaultMessage: 'Enter your state',
        description: '',
    },

    modal_buy_credits_form_vat_id: {
        id:  "modal.buy_credits.form.vat_id",
        defaultMessage: 'Vat ID',
        description: '',
    },

    modal_buy_credits_form_vat_id_placeholder: {
        id:  "modal.buy_credits.form.vat_id.placeholder",
        defaultMessage: '(optional)',
        description: '',
    },

    modal_buy_credits_purchase_success: {
        id:  "modal.buy_credits.purchase.success",
        defaultMessage: 'Purchase of {finalCreditAmount} Obalero credits was successful. Your current balance is {finalBalance} Obalero Credits.',
        description: '',
    },

    modal_buy_subscription_purchase_success: {
        id:  "modal.subscription.purchase.success",
        defaultMessage: 'Purchase of {finalSubscription} subscription with {finalSeats} was successful.',
        description: '',
    },

    modal_buy_credits_purchase_cancel: {
        id:  "modal.buy_credits.purchase.cancel",
        defaultMessage: 'Purchase of {finalPackage} was cancelled.',
        description: '',
    },


    modal_buy_credits_button_ok: {
        id:  "modal.buy_credits.button.ok",
        defaultMessage: 'Ok',
        description: '',
    },


    uploader_file_selected: {
        id: 'uploader_file.selected',
        defaultMessage: 'File selected',
        description: '',
    },
    uploader_file_description: {
        id: 'uploader_file.description',
        defaultMessage: 'Drop your file here or click to',
        description: '',
    },
    uploader_file_choose_file: {
        id: 'uploader_file.choose_file',
        defaultMessage: 'Choose file...',
        description: '',
    },
    uploader_file_change_file: {
        id: 'uploader_file.change_file',
        defaultMessage: 'Change file...',
        description: '',
    },
    editor_loading_prepairing: {
        id: 'editor.loading.prepairing',
        defaultMessage: 'Preparing environment...',
        description: '',
    },
    editor_loading_mixing: {
        id: 'editor.loading.mixing',
        defaultMessage: 'Mixing colors...',
        description: '',
    },
    editor_loading_finishing: {
        id: 'editor.loading.finishing',
        defaultMessage: 'Finishing scene...',
        description: '',
    },
    editor_loading_loading: {
        id: 'editor.loading.loading',
        defaultMessage: 'Loading package...',
        description: '',
    },
    editor_edit_window_top_title: {
        id: 'editor.edit_window.top.title',
        defaultMessage: 'Top',
        description: '',
    },
    editor_edit_window_top_shell_color_title: {
        id: 'editor.edit_window.top.shell_color.title',
        defaultMessage: 'Shell color',
        description: '',
    },
    editor_color_window_title: {
        id: 'editor.color_window.title',
        defaultMessage: 'Choose color',
        description: '',
    },
    editor_color_window_shell_title: {
        id: 'editor.color_window.shell.title',
        defaultMessage: 'Choose color Shells',
        description: '',
    },
    editor_color_window_bottle_title: {
        id: 'editor.color_window.bottle.title',
        defaultMessage: 'Choose bottle color',
        description: '',
    },
    editor_color_window_body_title: {
        id: 'editor.color_window.body.title',
        defaultMessage: 'Choose body color',
        description: '',
    },
    editor_color_window_opening_title: {
        id: 'editor.color_window.opening.title',
        defaultMessage: 'Choose opening color',
        description: '',
    },


    editor_edit_window_opening_title: {
        id: 'editor.edit_window.opening.title',
        defaultMessage: 'Opening',
        description: '',
    },
    editor_edit_window_opening_type_title: {
        id: 'editor.edit_window.opening.type.title',
        defaultMessage: 'Opening type',
        description: '',
    },
    editor_edit_window_bottle_color_title: {
        id: 'editor.edit_window.bottle.color.title',
        defaultMessage: 'Bottle color',
        description: '',
    },
    editor_edit_window_body_color_title: {
        id: 'editor.edit_window.body.color.title',
        defaultMessage: 'Body color',
        description: '',
    },
    editor_edit_window_fluid_color_title: {
        id: 'editor.edit_window.fluid.color.title',
        defaultMessage: 'Fluid color',
        description: '',
    },
    editor_edit_window_opening_color_title: {
        id: 'editor.edit_window.opening.color.title',
        defaultMessage: 'Opening color',
        description: '',
    },
    editor_edit_window_top_tab_color_title: {
        id: 'editor.edit_window.top.tab_color.title',
        defaultMessage: 'Tab color',
        description: '',
    },
    editor_color_window_tabs_title: {
        id: 'editor.color_window.tabs.title',
        defaultMessage: 'Choose color Tabs',
        description: '',
    },
    editor_edit_window_body_title: {
        id: 'editor.edit_window.body.title',
        defaultMessage: 'Body',
        description: '',
    },
    editor_edit_window_body_varnish: {
        id: 'editor.edit_window.body.varnish',
        defaultMessage: 'Varnish',
        description: '',
    },
    editor_edit_window_body_varnish_glossy: {
        id: 'editor.edit_window.body.varnish.glossy',
        defaultMessage: 'Glossy',
        description: '',
    },
    editor_edit_window_body_varnish_matt: {
        id: 'editor.edit_window.body.varnish.matt',
        defaultMessage: 'Matt',
        description: '',
    },
    editor_edit_window_body_front_face: {
        id: 'editor.edit_window.body.front.face',
        defaultMessage: 'Front face',
        description: '',
    },
    editor_edit_window_body_front_face_sideA: {
        id: 'editor.edit_window.body.front.face.sideA',
        defaultMessage: 'Side A',
        description: '',
    },
    editor_edit_window_body_front_face_sideB: {
        id: 'editor.edit_window.body.front.face.sideB',
        defaultMessage: 'Side B',
        description: '',
    },
    editor_edit_window_body_front_face_rotation: {
        id: 'editor.edit_window.body.front.face.rotation',
        defaultMessage: 'Front face rotation',
        description: '',
    },
    editor_edit_window_body_front_face_rotation_button_set: {
        id: 'editor.edit_window.body.front_face_rotation.button.set',
        defaultMessage: 'Set',
        description: '',
    },
    editor_edit_window_color_set_custom_color: {
        id: 'editor.edit_window.color.set_custom_color',
        defaultMessage: 'Set custom color',
        description: '',
    },
    editor_edit_window_body_cans_title: {
        id: 'editor.edit_window.body.cans.title',
        defaultMessage: 'Cans',
        description: '',
    },
    editor_edit_window_body_textures_title: {
        id: 'editor.edit_window.body.textures.title',
        defaultMessage: 'Textures',
        description: '',
    },
    editor_edit_window_textures_title: {
        id: 'editor.edit_window.textures.title',
        defaultMessage: 'Title',
        description: '',
    },
    editor_edit_window_textures_button_download: {
        id: 'editor.edit_window.textures.button.download',
        defaultMessage: 'Download design template',
        description: '',
    },
    editor_edit_window_textures_or: {
        id: 'editor.edit_window.textures.or',
        defaultMessage: 'or',
        description: '',
    },
    editor_edit_window_textures_design_title: {
        id: 'editor.edit_window.textures.design.title',
        defaultMessage: 'Design',
        description: '',
    },
    editor_edit_window_textures_metalness_title: {
        id: 'editor.edit_window.textures.metalness.title',
        defaultMessage: 'Metalness',
        description: '',
    },
    editor_edit_window_textures_alpha_title: {
        id: 'editor.edit_window.textures.alpha.title',
        defaultMessage: 'Alpha',
        description: '',
    },
    editor_edit_window_textures_design_status_active: {
        id: 'editor.edit_window.textures.design.status.active',
        defaultMessage: 'active',
        description: '',
    },
    editor_edit_window_textures_design_status_inactive: {
        id: 'editor.edit_window.textures.design.status.inactive',
        defaultMessage: 'inactive',
        description: '',
    },
    editor_edit_window_textures_description: {
        id: 'editor.edit_window.textures.description',
        defaultMessage: 'You can upload product design texture manually. This is an optional way.',
        description: '',
    },
    editor_edit_window_textures_button_manually: {
        id: 'editor.edit_window.textures.button.manually',
        defaultMessage: 'Start manually',
        description: '',
    },
    editor_edit_window_textures_button_more: {
        id: 'editor.edit_window.textures.button.more',
        defaultMessage: 'More',
        description: '',
    },
    editor_edit_window_textures_button_upload_illustrator: {
        id: 'editor.edit_window.textures.button.upload_illustrator',
        defaultMessage: 'Upload from Adobe Illustrator',
        description: '',
    },
    editor_edit_window_textures_button_manual_upload: {
        id: 'editor.edit_window.textures.button.manual_upload',
        defaultMessage: 'Manual upload',
        description: '',
    },
    editor_edit_window_textures_button_remove: {
        id: 'editor.edit_window.textures.button.remove',
        defaultMessage: 'Remove',
        description: '',
    },
    editor_button_cancel: {
        id: 'editor.button_cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    editor_button_ok: {
        id: 'editor.button_ok',
        defaultMessage: 'Ok',
        description: '',
    },
    editor_rotate_left_right_description: {
        id: 'editor.rotate_left_right.description',
        defaultMessage: 'Rotate product left/right by dragging mouse',
        description: '',
    },
    editor_background_title: {
        id: 'editor.background_title',
        defaultMessage: 'Background',
        description: '',
    },
    editor_tooltip_view: {
        id: 'editor.tooltip.view',
        defaultMessage: 'View',
        description: '',
    },
    editor_tooltip_comments: {
        id: 'editor.tooltip.comments',
        defaultMessage: 'Comments',
        description: '',
    },
    editor_tooltip_comments_locked: {
        id: 'editor.tooltip.comments.locked',
        defaultMessage: 'Comments are available from Basic plan',
        description: '',
    },
    editor_tooltip_grid: {
        id: 'editor.tooltip.grid',
        defaultMessage: 'Grid',
        description: '',
    },
    editor_tooltip_ruler: {
        id: 'editor.tooltip.ruler',
        defaultMessage: 'Ruler',
        description: '',
    },
    editor_tooltip_developer_tools: {
        id: 'editor.tooltip.developer_tools',
        defaultMessage: 'Developer tools',
        description: '',
    },

    modal_confirm_render_title: {
        id: 'modal.confirm.render_title',
        defaultMessage: 'Create a new packshot',
        description: '',
    },

    modal_confirm_render_description: {
        id: 'modal.confirm.render_description',
        defaultMessage: 'New packshot will be created for {creditAmount} Obalero credits. Rendering will take about {renderTime} minutes. We will notify you when the packshot will be ready.',
        description: '',
    },
    packshots_download: {
        id: 'packshots.download',
        defaultMessage: 'Stáhnout',
        description: '',
    },
    packshots_create: {
        id: 'packshots.create',
        defaultMessage: 'Create packshot',
        description: '',
    },
    packshots_created: {
        id: 'packshots.created',
        defaultMessage: 'Created',
        description: '',
    },
    packshots_request_custom_view: {
        id: 'packshots.request_custom_view',
        defaultMessage: 'Request custom view',
        description: '',
    },
    packshots_credits_available: {
        id: 'packshots.credits.available',
        defaultMessage: 'Credits available',
        description: '',
    },
    packshots_lowres_description: {
        id: 'packshots.lowres.description',
        defaultMessage: 'Nízké rozlišení je optimalizováno pro digitální použití, kdy je prioritou rychlé načítání a menší velikost souboru, také se skvěle hodí pro rychlé náhledy.',
        description: '',
    },
    packshots_midres_description: {
        id: 'packshots.midres.description',
        defaultMessage: 'Střední rozlišení nabízí vyvážený kompromis mezi kvalitou a velikostí souboru. Hodí se pro online prezentaci i tisk menších materiálů, jako jsou katalogy či brožury.',
        description: '',
    },
    packshots_highres_description: {
        id: 'packshots.highres.description',
        defaultMessage: 'Vysoké rozlišení zaručuje maximální kvalitu a precizní detaily, což je nezbytné pro profesionální tisk a velkoformátové reklamní kampaně.',
        description: '',
    },
    packshots_size_description: {
        id: 'packshots.size.description',
        defaultMessage: 'Size',
        description: '',
    },
    packshots_created_description: {
        id: 'packshots.created.description',
        defaultMessage: 'Created',
        description: '',
    },

    editor_comments_open: {
        id: 'editor.comments.open',
        defaultMessage: 'Open',
        description: '',
    },




    editor_comments_resolved: {
        id: 'editor.comments.resolved',
        defaultMessage: 'Resolved',
        description: '',
    },




    editor_comments_rejected: {
        id: 'editor.comments.rejected',
        defaultMessage: 'Rejected',
        description: '',
    },



    editor_comments_cancelled: {
        id: 'editor.comments.cancelled',
        defaultMessage: 'Cancelled',
        description: '',
    },





    editor_comments_edit: {
        id: 'editor.comments.edit',
        defaultMessage: 'Edit',
        description: '',
    },



    editor_comments_delete: {
        id: 'editor.comments.delete',
        defaultMessage: 'Delete',
        description: '',
    },



    editor_comments_reply: {
        id: 'editor.comments.reply',
        defaultMessage: 'Reply',
        description: '',
    },
    editor_comments_comment: {
        id: 'editor.comments.comment',
        defaultMessage: 'Comment',
        description: '',
    },

    editor_comments_save: {
        id: 'editor.comments.save',
        defaultMessage: 'Save',
        description: '',
    },



    editor_comments_all: {
        id: 'editor.comments.all',
        defaultMessage: 'All',
        description: '',
    },
    editor_comments_unresolved: {
        id: 'editor.comments.unresolved',
        defaultMessage: 'Unresolved',
        description: '',
    },

    editor_comments_cancel: {
        id: 'editor.comments.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    performance_message: {
        id: 'performance.message',
        defaultMessage: 'We detect problem with performance, in the FAQ you will find minimal requirements for your device',
        description: '',
    },
    editor_comments_new: {
        id: 'editor.comments.new',
        defaultMessage: 'New comment',
        description: '',
    },
    editor_comments_title: {
        id: 'editor.comments.title',
        defaultMessage: 'Comments',
        description: '',
    },
    editor_comments_add: {
        id: 'editor.comments.add',
        defaultMessage: 'Add comment',
        description: '',
    },
    newproduct_title: {
        id: 'newproduct.title',
        defaultMessage: 'New product',
        description: '',
    },
    newproduct_breadcrumbs_category: {
        id: 'newproduct.breadcrumbs.category',
        defaultMessage: 'Category',
        description: '',
    },
    newproduct_breadcrumbs_product: {
        id: 'newproduct.breadcrumbs.product',
        defaultMessage: 'Product',
        description: '',
    },
    newproduct_breadcrumbs_name: {
        id: 'newproduct.breadcrumbs.name',
        defaultMessage: 'Name',
        description: '',
    },
    newproduct_buttons_cancel: {
        id: 'newproduct.buttons.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    newproduct_form_product_name: {
        id: 'newproduct.form.product.name',
        defaultMessage: 'Product name',
        description: '',
    },
    newproduct_form_coming_soon: {
        id: 'newproduct.form.coming_soon',
        defaultMessage: 'Coming soon',
        description: '',
    },
    newproduct_form_optional_sentence: {
        id: 'newproduct.form.optional.sentence',
        defaultMessage: 'Optionally, you can assign product to the client and brand',
        description: '',
    },
    packshot_detail_title: {
        id: 'packshot_detail.title',
        defaultMessage: 'Packshot detail',
        description: '',
    },
    product_detail_title: {
        id: 'product_detail.title',
        defaultMessage: 'Product detail',
        description: '',
    },
    product_detail_button_new: {
        id: 'product_detail.button.new',
        defaultMessage: 'New version',
        description: '',
    },
    modal_upload_texture_editor_title: {
        id: 'modal.upload_texture.editor.title',
        defaultMessage: 'Upload texture',
        description: '',
    },
    modal_upload_texture_editor_uploading_title: {
        id: 'modal.upload_texture.editor.uploading_title',
        defaultMessage: 'Uploading texture ...',
        description: '',
    },
    modal_texture_editor_install_plugin_1: {
        id: 'modal.texture_editor_install_plugin_1',
        defaultMessage: 'Install our Adobe Illustrator plugin trough the ',
        description: '',
    },
    modal_texture_editor_install_plugin_2: {
        id: 'modal.texture_editor_install_plugin_2',
        defaultMessage: 'for more information please visit our section ',
        description: '',
    },
    modal_upload_texture_editor_error_title: {
        id: 'modal.upload_texture.editor.error_title',
        defaultMessage: 'Problem with uploading texture :(',
        description: '',
    },
    modal_upload_texture_editor_illustrator_title: {
        id: 'modal.upload_texture_editor.illustrator.title',
        defaultMessage: 'Upload texture from Adobe Illustrator',
        description: '',
    },
    modal_upload_texture_editor_description: {
        id: 'modal.upload_texture.editor.description',
        defaultMessage: 'Please, upload texture meeting those specifications: width: {width} px, height: {height} px, color mode: RGB (8 bit). File type .jpg, .jpeg or .png.',
        description: '',
    },
    modal_upload_texture_editor_description_more: {
        id: 'modal.upload_texture.editor.description_more',
        defaultMessage: 'More information here.',
        description: '',
    },
    modal_upload_texture_editor_button_cancel: {
        id: 'modal.upload_texture.editor.button.cancel',
        defaultMessage: 'Cancel',
        description: '',
    },
    modal_upload_texture_editor_button_try_again: {
        id: 'modal.upload_texture.editor.button.try_again',
        defaultMessage: 'Try again',
        description: '',
    },
    plugin_buttons_back: {
        id: 'plugin.buttons.back',
        defaultMessage: 'Back',
        description: '',
    },
    plugin_buttons_publish: {
        id: 'plugin.buttons.publish',
        defaultMessage: 'Publish design',
        description: '',
    },
    plugin_buttons_change_layers: {
        id: 'plugin.buttons.change_layers',
        defaultMessage: 'Change layers',
        description: '',
    },
    plugin_buttons_package_options: {
        id: 'plugin.buttons.package_options',
        defaultMessage: 'Package options',
        description: '',
    },
    plugin_buttons_open_in_browser: {
        id: 'plugin.buttons.open_in_browser',
        defaultMessage: 'Open in browser',
        description: '',
    },
    plugin_buttons_more: {
        id: 'plugin.buttons.more',
        defaultMessage: 'More',
        description: '',
    },
    plugin_buttons_developer_tools: {
        id: 'plugin.buttons.developer_tools',
        defaultMessage: 'Developer tools',
        description: '',
    },
    plugin_buttons_settings : {
        id: 'plugin.buttons.settings',
        defaultMessage: 'Settings',
        description: '',
    },
    plugin_buttons_export : {
        id: 'plugin.buttons.export',
        defaultMessage: 'Export texture to file',
        description: '',
    },
    plugin_buttons_switch_teams : {
        id: 'plugin.buttons.switch_teams',
        defaultMessage: 'Switch teams',
        description: '',
    },
    plugin_buttons_sign_out : {
        id: 'plugin.buttons.sign_out',
        defaultMessage: 'Sign out',
        description: '',
    },
    plugin_buttons_contact_support : {
        id: 'plugin.buttons.contact_support',
        defaultMessage: 'Contact support',
        description: '',
    },
    modal_alert_information_title : {
        id: 'modal.alert.information_title',
        defaultMessage: 'Information message',
        description: '',
    },
    modal_alert_warning_title : {
        id: 'modal.alert.warning_title',
        defaultMessage: 'Warning message',
        description: '',
    },
    modal_alert_error_title : {
        id: 'modal.alert.error_title',
        defaultMessage: 'Error message',
        description: '',
    },

    modal_alert_button_no_layers :{
        id: 'modal.alert.button.no_layers',
        defaultMessage: 'No, I will Change Layers',
        description: '',
    },
    modal_alert_button_no : {
        id: 'modal.alert.button.no',
        defaultMessage: 'No',
        description: '',
    },
    modal_alert_button_yes : {
        id: 'modal.alert.button.yes',
        defaultMessage: 'Yes',
        description: '',
    },
    modal_alert_button_ok:{
        id: 'modal.alert.button.ok',
        defaultMessage: 'OK',
        description: '',
    },
    ////////////////////**///////


    API_AUTH_BAD_CREDENTIALS:{
        id: 'API_AUTH_BAD_CREDENTIALS',
        defaultMessage: 'OK',
        description: '',
    },
    API_AUTH_INVALID_JWT_TOKEN:{
        id: 'API_AUTH_INVALID_JWT_TOKEN',
        defaultMessage: 'OK',
        description: '',
    },

    API_AUTH_EXPIRED_JWT_TOKEN:{
        id: 'API_AUTH_EXPIRED_JWT_TOKEN',
        defaultMessage: 'OK',
        description: '',
    },

    API_AUTH_USER_BANNED:{
        id: 'API_AUTH_USER_BANNED',
        defaultMessage: 'OK',
        description: '',
    },
    API_AUTH_REGISTRATION_NOT_COMPLETED:{
        id: 'API_AUTH_REGISTRATION_NOT_COMPLETED',
        defaultMessage: 'OK',
        description: '',
    },

    API_AUTH_UNAUTHORIZED:{
        id: 'API_AUTH_UNAUTHORIZED',
        defaultMessage: 'OK',
        description: '',
    },

    API_AUTH_INVALID_EMAIL_TOKEN:{
        id: 'API_AUTH_INVALID_EMAIL_TOKEN',
        defaultMessage: 'OK',
        description: '',
    },
    API_AUTH_EMAIL_TOKEN_EXPIRED:{
        id: 'API_AUTH_EMAIL_TOKEN_EXPIRED',
        defaultMessage: 'OK',
        description: '',
    },


    API_AUTH_USER_NOT_FOUND:{
        id: 'API_AUTH_USER_NOT_FOUND',
        defaultMessage: 'OK',
        description: '',
    },
    /*/

     */

    API_AUTH_NOT_TEAM_MEMBER:{
        id: 'API_AUTH_NOT_TEAM_MEMBER',
        defaultMessage: 'OK',
        description: '',
    },
    API_AUTH_NO_TEAM_IN_TOKEN:{
        id: 'API_AUTH_NO_TEAM_IN_TOKEN',
        defaultMessage: 'OK',
        description: '',
    },
    API_AUTH_REGISTRATION_REJECTED_EMAIL:{
        id: 'API_AUTH_REGISTRATION_REJECTED_EMAIL',
        defaultMessage: 'OK',
        description: '',
    },
    API_USERS_USER_ALREADY_EXISTS:{
        id: 'API_USERS_USER_ALREADY_EXISTS',
        defaultMessage: 'OK',
        description: '',
    },
    API_USER_ACCOUNT_SSO_MAIL_MISMATCH:{
        id: 'API_USER_ACCOUNT_SSO_MAIL_MISMATCH',
        defaultMessage: 'OK',
        description: '',
    },
    API_USER_PASSWORD_NOT_SET:{
        id: 'API_USER_PASSWORD_NOT_SET',
        defaultMessage: 'OK',
        description: '',
    },

    API_USER_NOT_FOUND:{
        id: 'API_USER_NOT_FOUND',
        defaultMessage: 'OK',
        description: '',
    },
//*/
    API_TEAM_MEMBER_REMOVING_HIMSELF:{
        id: 'API_TEAM_MEMBER_REMOVING_HIMSELF',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_MEMBER_CAN_NOT_REMOVE_LAST_OWNER:{
        id: 'API_TEAM_MEMBER_CAN_NOT_REMOVE_LAST_OWNER',
        defaultMessage: 'OK',
        description: '',
    },



    API_TEAM_MEMBER_CAN_NOT_CHANGE_OWN_ROLE:{
        id: 'API_TEAM_MEMBER_CAN_NOT_CHANGE_OWN_ROLE',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_INVITATION_SEND_LIMIT:{
        id: 'API_TEAM_INVITATION_SEND_LIMIT',
        defaultMessage: 'OK',
        description: '',
    },


    API_REGISTRATION_SEND_LIMIT:{
        id: 'API_REGISTRATION_SEND_LIMIT',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_INVITATION_EXPIRED:{
        id: 'API_TEAM_INVITATION_EXPIRED',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_REACHED_SEATS_LIMIT:{
        id: 'API_TEAM_REACHED_SEATS_LIMIT',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_MEMBER_EXISTS:{
        id: 'API_TEAM_MEMBER_EXISTS',
        defaultMessage: 'OK',
        description: '',
    },




    API_TEAM_MEMBER_ALREADY_INVITED:{
        id: 'API_TEAM_MEMBER_ALREADY_INVITED',
        defaultMessage: 'OK',
        description: '',
    },





    API_TEAM_HAVE_ACTIVE_MEMBERS:{
        id: 'API_TEAM_HAVE_ACTIVE_MEMBERS',
        defaultMessage: 'OK',
        description: '',
    },




    API_LAST_MEMBER_CANNOT_LEAVE:{
        id: 'API_LAST_MEMBER_CANNOT_LEAVE',
        defaultMessage: 'OK',
        description: '',
    },




    API_USER_HAVE_ACTIVE_TEAMS:{
        id: 'API_USER_HAVE_ACTIVE_TEAMS',
        defaultMessage: 'OK',
        description: '',
    },


//*/
    API_FREE_TEAMS_LIMIT_REACHED:{
        id: 'API_FREE_TEAMS_LIMIT_REACHED',
        defaultMessage: 'OK',
        description: '',
    },



    API_TEAM_BILLING_INFO_UPDATE_FAILED:{
        id: 'API_TEAM_BILLING_INFO_UPDATE_FAILED',
        defaultMessage: 'OK',
        description: '',
    },



    API_TEAM_BILLING_INFO_INVALID:{
        id: 'API_TEAM_BILLING_INFO_INVALID',
        defaultMessage: 'OK',
        description: '',
    },



    API_TEAM_BILLING_INFO_NOT_COMPLETE:{
        id: 'API_TEAM_BILLING_INFO_NOT_COMPLETE',
        defaultMessage: 'OK',
        description: '',
    },



    API_PAYMENT_ACTIVE_SUBSCRIPTION:{
        id: 'API_PAYMENT_ACTIVE_SUBSCRIPTION',
        defaultMessage: 'OK',
        description: '',
    },





    API_SUBSCRIPTION_UPDATE_ERROR:{
        id: 'API_SUBSCRIPTION_UPDATE_ERROR',
        defaultMessage: 'OK',
        description: '',
    },




    API_NOT_ENOUGH_CREDITS:{
        id: 'API_NOT_ENOUGH_CREDITS',
        defaultMessage: 'OK',
        description: '',
    },




    API_SUBSCRIPTION_UPGRADE_REQUIRED:{
        id: 'API_SUBSCRIPTION_UPGRADE_REQUIRED',
        defaultMessage: 'OK',
        description: '',
    },




    API_PRODUCT_LOCKED_DUE_TO_DOWNGRADED_SUBSCRIPTION:{
        id: 'API_PRODUCT_LOCKED_DUE_TO_DOWNGRADED_SUBSCRIPTION',
        defaultMessage: 'OK',
        description: '',
    },




    API_PRODUCT_VERSION_LOCKED:{
        id: 'API_PRODUCT_VERSION_LOCKED',
        defaultMessage: 'OK',
        description: '',
    },



    API_SHARE_SUB_SHARES_HAS_LOWER_PERMISSIONS:{
        id: 'API_SHARE_SUB_SHARES_HAS_LOWER_PERMISSIONS',
        defaultMessage: 'OK',
        description: '',
    },




    API_SHARE_MEMBER_LOWER_PERMISSIONS:{
        id: 'API_SHARE_MEMBER_LOWER_PERMISSIONS',
        defaultMessage: 'OK',
        description: '',
    },




    API_SHARE_MEMBER_NOT_FOUND:{
        id: 'API_SHARE_MEMBER_NOT_FOUND',
        defaultMessage: 'OK',
        description: '',
    },





    API_SHARE_MEMBER_TRYING_REMOVE_SELF:{
        id: 'API_SHARE_MEMBER_TRYING_REMOVE_SELF',
        defaultMessage: 'OK',
        description: '',
    },





    API_SHARE_MEMBER_ALREADY_EXISTS:{
        id: 'API_SHARE_MEMBER_ALREADY_EXISTS',
        defaultMessage: 'OK',
        description: '',
    },





    API_SKETCH_INVALID_FORMAT:{
        id: 'API_SKETCH_INVALID_FORMAT',
        defaultMessage: 'OK',
        description: '',
    },

    API_PACKAGES_PARENT_GROUP_NOT_FOUND:{
        id: 'API_PACKAGES_PARENT_GROUP_NOT_FOUND',
        defaultMessage: 'OK',
        description: '',
    },
    COMMENT_NOT_BELONG_TO_PROVIDED_CORRECTION:{
        id: 'COMMENT_NOT_BELONG_TO_PROVIDED_CORRECTION',
        defaultMessage: 'OK',
        description: '',
    },


    API_VALIDATION_FAILED:{
        id: 'API_VALIDATION_FAILED',
        defaultMessage: 'API_VALIDATION_FAILED',
        description: '',
    },



    API_NOT_FOUND:{
        id: 'API_NOT_FOUND',
        defaultMessage: 'API_NOT_FOUND',
        description: '',
    },


    API_FORBIDDEN:{
        id: 'API_FORBIDDEN',
        defaultMessage: 'Forbidden',
        description: '',
    },


    API_NO_DATA_PROVIDED:{
        id: 'API_NO_DATA_PROVIDED',
        defaultMessage: 'API_NO_DATA_PROVIDED',
        description: '',
    },

    API_UNEXPECTED_ERROR:{
        id: 'API_UNEXPECTED_ERROR',
        defaultMessage: 'API_UNEXPECTED_ERROR',
        description: '',
    },

    API_BAD_REQUEST:{
        id: 'API_BAD_REQUEST',
        defaultMessage: 'API_BAD_REQUEST',
        description: '',
    },

    API_RELATED_ENTITY_DELETING:{
        id: 'API_RELATED_ENTITY_DELETING',
        defaultMessage: 'API_RELATED_ENTITY_DELETING',
        description: '',
    },
    API_SUCCESS:{
        id: 'API_SUCCESS',
        defaultMessage: 'API_SUCCESS',
        description: '',
    },

    API_AUTH_SSO_UNLINKED:{
        id: 'API_AUTH_SSO_UNLINKED',
        defaultMessage: 'API_AUTH_SSO_UNLINKED',
        description: '',
    },

    API_AUTH_USER_REGISTERED:{
        id: 'API_AUTH_USER_REGISTERED',
        defaultMessage: 'API_AUTH_USER_REGISTERED',
        description: '',
    },

    API_AUTH_PASSWORD_RECOVERY_SENT:{
        id: 'API_AUTH_PASSWORD_RECOVERY_SENT',
        defaultMessage: 'API_AUTH_PASSWORD_RECOVERY_SENT',
        description: '',
    },
    API_AUTH_USER_PASSWORD_VALID:{
        id: 'API_AUTH_USER_PASSWORD_VALID',
        defaultMessage: 'API_AUTH_USER_PASSWORD_VALID',
        description: '',
    },


    //*//

    API_COMMENTS_COMMENT_CREATED:{
        id: 'API_COMMENTS_COMMENT_CREATED',
        defaultMessage: 'API_COMMENTS_COMMENT_CREATED',
        description: '',
    },
    API_COMMENTS_COMMENT_UPDATED:{
        id: 'API_COMMENTS_COMMENT_UPDATED',
        defaultMessage: 'API_COMMENTS_COMMENT_UPDATED',
        description: '',
    },
    API_COMMENTS_COMMENT_DELETED:{
        id: 'API_COMMENTS_COMMENT_DELETED',
        defaultMessage: 'API_COMMENTS_COMMENT_DELETED',
        description: '',
    },

    API_COMMENTS_CORRECTION_CREATED:{
        id: 'API_COMMENTS_CORRECTION_CREATED',
        defaultMessage: 'API_COMMENTS_CORRECTION_CREATED',
        description: '',
    },

    API_COMMENTS_CORRECTION_UPDATED:{
        id: 'API_COMMENTS_CORRECTION_UPDATED',
        defaultMessage: 'API_COMMENTS_CORRECTION_UPDATED',
        description: '',
    },

    API_COMMENTS_CORRECTION_DELETED:{
        id: 'API_COMMENTS_CORRECTION_DELETED',
        defaultMessage: 'API_COMMENTS_CORRECTION_DELETED',
        description: '',
    },


    API_GROUPS_GROUP_CREATED:{
        id: 'API_GROUPS_GROUP_CREATED',
        defaultMessage: 'API_GROUPS_GROUP_CREATED',
        description: '',
    },


    API_GROUPS_GROUP_UPDATED:{
        id: 'API_GROUPS_GROUP_UPDATED',
        defaultMessage: 'API_GROUPS_GROUP_UPDATED',
        description: '',
    },

    API_GROUPS_DELETED:{
        id: 'API_GROUPS_DELETED',
        defaultMessage: 'API_GROUPS_DELETED',
        description: '',
    },


    API_GROUPS_CAN_BE_DELETED:{
        id: 'API_GROUPS_CAN_BE_DELETED',
        defaultMessage: 'API_GROUPS_CAN_BE_DELETED',
        description: '',
    },


    API_NOTIFICATIONS_NOTIFICATION_UPDATED:{
        id: 'API_NOTIFICATIONS_NOTIFICATION_UPDATED',
        defaultMessage: 'API_NOTIFICATIONS_NOTIFICATION_UPDATED',
        description: '',
    },


    API_PRODUCT_VERSION_DELETING_LAST_PRODUCT_VERSION:{
        id: 'API_PRODUCT_VERSION_DELETING_LAST_PRODUCT_VERSION',
        defaultMessage: 'API_PRODUCT_VERSION_DELETING_LAST_PRODUCT_VERSION',
        description: '',
    },



    API_NOTIFICATIONS_SEEN:{
        id: 'API_NOTIFICATIONS_SEEN',
        defaultMessage: 'API_NOTIFICATIONS_SEEN',
        description: '',
    },

    API_PRODUCTS_PRODUCT_CREATED:{
        id: 'API_PRODUCTS_PRODUCT_CREATED',
        defaultMessage: 'API_PRODUCTS_PRODUCT_CREATED',
        description: '',
    },

    API_PRODUCTS_PRODUCT_UPDATED:{
        id: 'API_PRODUCTS_PRODUCT_UPDATED',
        defaultMessage: 'API_PRODUCTS_PRODUCT_UPDATED',
        description: '',
    },


    API_PRODUCTS_CAN_BE_DELETED:{
        id: 'API_PRODUCTS_CAN_BE_DELETED',
        defaultMessage: 'API_PRODUCTS_CAN_BE_DELETED',
        description: '',
    },
    API_PRODUCTS_DELETED:{
        id: 'API_PRODUCTS_DELETED',
        defaultMessage: 'API_PRODUCTS_DELETED',
        description: '',
    },

    API_PRODUCTS_PRODUCT_VERSIONS_VERSION_CREATED:{
        id: 'API_PRODUCTS_PRODUCT_VERSIONS_VERSION_CREATED',
        defaultMessage: 'API_PRODUCTS_PRODUCT_VERSIONS_VERSION_CREATED',
        description: '',
    },

    API_PRODUCTS_PRODUCT_VERSIONS_VERSION_UPDATED:{
        id: 'API_PRODUCTS_PRODUCT_VERSIONS_VERSION_UPDATED',
        defaultMessage: 'API_PRODUCTS_PRODUCT_VERSIONS_VERSION_UPDATED',
        description: '',
    },
    API_PRODUCTS_PRODUCT_VERSIONS_CAN_BE_DELETED:{
        id: 'API_PRODUCTS_PRODUCT_VERSIONS_CAN_BE_DELETED',
        defaultMessage: 'API_PRODUCTS_PRODUCT_VERSIONS_CAN_BE_DELETED',
        description: '',
    },
    API_PRODUCTS_PRODUCT_VERSIONS_DELETED:{
        id: 'API_PRODUCTS_PRODUCT_VERSIONS_DELETED',
        defaultMessage: 'API_PRODUCTS_PRODUCT_VERSIONS_DELETED',
        description: '',
    },
    API_RENDER_JOBS_RENDER_JOB_DELETED:{
        id: 'API_RENDER_JOBS_RENDER_JOB_DELETED',
        defaultMessage: 'API_RENDER_JOBS_RENDER_JOB_DELETED',
        description: '',
    },

    API_SHARES_SHARE_UPDATED:{
        id: 'API_SHARES_SHARE_UPDATED',
        defaultMessage: 'API_SHARES_SHARE_UPDATED',
        description: '',
    },

    API_SHARES_SHARE_CREATED:{
        id: 'API_SHARES_SHARE_CREATED',
        defaultMessage: 'OK',
        description: '',
    },


    API_SHARES_SHARE_MEMBERS_INVITED:{
        id: 'API_SHARES_SHARE_MEMBERS_INVITED',
        defaultMessage: 'OK',
        description: '',
    },

    API_SHARES_SHARE_MEMBER_UPDATED:{
        id: 'API_SHARES_SHARE_MEMBER_UPDATED',
        defaultMessage: 'OK',
        description: '',
    },



    API_SHARES_SHARE_MEMBER_DELETED:{
        id: 'API_SHARES_SHARE_MEMBER_DELETED',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_INVITATION_ACCEPTED:{
        id: 'API_TEAM_INVITATION_ACCEPTED',
        defaultMessage: 'OK',
        description: '',
    },
    API_TEAM_INVITATION_REJECTED:{
        id: 'API_TEAM_INVITATION_REJECTED',
        defaultMessage: 'OK',
        description: '',
    },
    API_TEAM_INVITATION_RECREATED:{
        id: 'API_TEAM_INVITATION_RECREATED',
        defaultMessage: 'OK',
        description: '',
    },

    API_TEAM_MEMBER_CREATED:{
        id: 'API_TEAM_MEMBER_CREATED',
        defaultMessage: 'OK',
        description: '',
    },

    API_TEAM_MEMBER_UPDATED:{
        id: 'API_TEAM_MEMBER_UPDATED',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_MEMBER_DELETED:{
        id: 'API_TEAM_MEMBER_DELETED',
        defaultMessage: 'OK',
        description: '',
    },
    API_TEAM_CREATED:{
        id: 'API_TEAM_CREATED',
        defaultMessage: 'OK',
        description: '',
    },


    API_TEAM_UPDATED:{
        id: 'API_TEAM_UPDATED',
        defaultMessage: 'OK',
        description: '',
    },

    API_TEAM_DELETED:{
        id: 'API_TEAM_DELETED',
        defaultMessage: 'OK',
        description: '',
    },



    API_USER_UPDATED:{
        id: 'API_USER_UPDATED',
        defaultMessage: 'OK',
        description: '',
    },




    API_USER_DELETED:{
        id: 'API_USER_DELETED',
        defaultMessage: 'OK',
        description: '',
    },
    plugin_permissions_template:{
        id: 'plugin.permissions.template',
        defaultMessage: "You don't have permission to view this product",
        description:"You don't have permission to view this product",
    },
    plugin_contact_owner:{
        id: 'plugin.contact.owner',
        defaultMessage: 'Please contact owner of this template to give you access',
        description: 'Please contact owner of this template to give you access',
    },
    plugin_welcome_header:{
        id: 'plugin.welcome.header',
        defaultMessage: 'Welcome to the Obalero plugin',
        description: 'Welcome to the Obalero plugin',
    },

    plugin_select_your_team_title:{
        id: 'plugin.select_your_team.title',
        defaultMessage: 'Select your team to continue, please',
        description: 'Select your team to continue, please',
    },

    plugin_welcome_description_header:{
        id: 'plugin.welcome.description.header',
        defaultMessage: 'Turn your Illustrator designs into stunning 3D mockups in just a few simple steps:',
        description: 'Turn your Illustrator designs into stunning 3D mockups in just a few simple steps:',
    },
    plugin_welcome_description_login_1:{
            id: 'plugin.welcome_description_login_1',
            defaultMessage: 'Turn your Illustrator designs into stunning 3D mockups in just a few simple steps:',
            description: 'Turn your Illustrator designs into stunning 3D mockups in just a few simple steps:',
        },
    plugin_welcome_description_login_2:{
        id: 'plugin.welcome_description_login_2',
        defaultMessage: 'Create a product and download the matching AI template',
        description: 'Create a product and download the matching AI template',
    },
    plugin_welcome_description_3:{
        id: 'plugin.welcome_description_3',
        defaultMessage: 'Open the AI template with the Obalero plugin, make your design changes on the layer named',
        description: 'Open the AI template with the Obalero plugin, make your design changes on the layer named',
    },
    plugin_welcome_description_4:{
        id: 'plugin.welcome_description_4',
        defaultMessage: 'Hit the Publish button to see your changes come to life in 3D',
        description: 'Hit the Publish button to see your changes come to life in 3D',
    },
    plugin_not_a_template_header:{
        id: 'plugin.not_a_template_header',
        defaultMessage: 'Not an Obalero template',
        description: 'Not an Obalero template',
    },
    plugin_not_template_description_1:{
        id: 'plugin.not_template_description_1',
        defaultMessage: 'We didn\'t find any paired design template please log in to the web app at',
        description: 'We didn\'t find any paired design template please log in to the web app at',
    },


    plugin_create_new_product:{
        id: 'plugin_create_new_product',
        defaultMessage: 'Create new product',
        description: 'Create new product',
    },



});
export default definedMessages;
